define("discourse/plugins/discourse-ebay-ads/discourse/controllers/ebay-search", ["exports", "@ember/controller", "discourse/lib/ajax", "@ember/object", "@glimmer/tracking", "@ember/runloop"], function (_exports, _controller, _ajax, _object, _tracking, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "ebayListings", [_tracking.tracked], function () {
      return [];
    }))();
    #ebayListings = (() => (dt7948.i(this, "ebayListings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "totalCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #totalCount = (() => (dt7948.i(this, "totalCount"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "impressionList", [_tracking.tracked], function () {
      return [];
    }))();
    #impressionList = (() => (dt7948.i(this, "impressionList"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "hasMore", [_tracking.tracked], function () {
      return true;
    }))();
    #hasMore = (() => (dt7948.i(this, "hasMore"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "search_keys", [_tracking.tracked], function () {
      return "";
    }))();
    #search_keys = (() => (dt7948.i(this, "search_keys"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "filtered_username", [_tracking.tracked], function () {
      return null;
    }))();
    #filtered_username = (() => (dt7948.i(this, "filtered_username"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "mode_row", [_tracking.tracked], function () {
      return true;
    }))();
    #mode_row = (() => (dt7948.i(this, "mode_row"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "selectedCompany", [_tracking.tracked], function () {
      return 'all';
    }))();
    #selectedCompany = (() => (dt7948.i(this, "selectedCompany"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "selectedGrade", [_tracking.tracked], function () {
      return true;
    }))();
    #selectedGrade = (() => (dt7948.i(this, "selectedGrade"), void 0))();
    limit = 20;
    offset = 0;
    observer = null;
    init() {
      super.init();
      this.flushTimer = null;
      this.flushInterval = 500;
      this.loadEbayListings(true);
      (0, _runloop.scheduleOnce)('afterRender', this, this.setupScrollObserver);
    }
    loadEbayListings(force) {
      if (!force && (this.isLoading || !this.hasMore)) return;
      this.isLoading = true;
      let url = `/ebay/search.json?limit=${this.limit}&offset=${this.offset}`;
      if (this.search_keys) {
        url = url + "&search_keys=" + encodeURIComponent(this.search_keys);
      }
      if (this.filtered_username) {
        url = url + "&username=" + encodeURIComponent(this.filtered_username);
      }
      (0, _ajax.ajax)(url).then(result => {
        if (result.ebay_listings.length < this.limit) {
          this.hasMore = false;
        }
        this.totalCount = result.total_count;
        this.ebayListings = [...this.ebayListings, ...result.ebay_listings];
        this.offset += this.limit;
        this.isLoading = false;
        const container = document.querySelector('#listings-container');
        const observer = new MutationObserver((mutations, obs) => {
          result.ebay_listings.forEach(item => {
            this.setupImpressionObserver(item.item_id);
          });
          obs.disconnect();
        });
        observer.observe(container, {
          childList: true
        });
      }).catch(error => {
        this.isLoading = false;
        console.error('Error fetching eBay listings:', error);
      });
    }
    onChangeSearchForUsername(username) {
      this.filtered_username = username;
      this.offset = 0;
      this.ebayListings = [];
      this.loadEbayListings(true);
    }
    static #_11 = (() => dt7948.n(this.prototype, "onChangeSearchForUsername", [_object.action]))();
    updateSearch(search_text) {
      this.search_keys = search_text;
      this.offset = 0;
      this.ebayListings = [];
      this.loadEbayListings(true);
    }
    static #_12 = (() => dt7948.n(this.prototype, "updateSearch", [_object.action]))();
    updateSelectedGrade() {}
    static #_13 = (() => dt7948.n(this.prototype, "updateSelectedGrade", [_object.action]))();
    updateSelectedCompany(company) {
      this.selectedCompany = company;
      console.log(this.selectedCompany);
    }
    static #_14 = (() => dt7948.n(this.prototype, "updateSelectedCompany", [_object.action]))();
    switchModeGrid() {
      this.mode_row = false;
    }
    static #_15 = (() => dt7948.n(this.prototype, "switchModeGrid", [_object.action]))();
    switchModeRow() {
      this.mode_row = true;
    }
    static #_16 = (() => dt7948.n(this.prototype, "switchModeRow", [_object.action]))();
    trackEbayClick(itemId) {
      const encodedId = encodeURIComponent(itemId);
      let url = `/ebay/adclick/${encodedId}`;
      (0, _ajax.ajax)(url).then(result => {}).catch(error => {
        console.error('Click not recorded:', error);
      });
    }
    static #_17 = (() => dt7948.n(this.prototype, "trackEbayClick", [_object.action]))();
    trackEbayImpression(itemId) {
      this.impressionList.push(itemId);
      if (this.flushTimer) {
        clearTimeout(this.flushTimer);
      }
      if (this.impressionList.length >= 20) {
        this.flushImpressions();
      } else {
        this.flushTimer = setTimeout(() => {
          this.flushImpressions();
        }, this.flushInterval);
      }
    }
    flushImpressions() {
      console.log(this.impressionList.length, this.impressionList);
      if (this.impressionList.length > 0) {
        const encodedItemsList = Array.from(this.impressionList).map(item => encodeURIComponent(item));
        const encodedItems = encodedItemsList.join('&');
        const url = `/ebay/adimpression/${encodedItems}`;
        this.impressionList.clear();
        (0, _ajax.ajax)(url).then(result => {}).catch(error => {
          console.error('Failed to send impressions:', error);
        });
      }
      this.flushTimer = null;
    }
    onScrollToEnd() {
      this.loadEbayListings(false);
    }
    static #_18 = (() => dt7948.n(this.prototype, "onScrollToEnd", [_object.action]))();
    setupScrollObserver() {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      this.observer = new IntersectionObserver(_ref => {
        let [entry] = _ref;
        if (entry.isIntersecting && !this.isLoading) {
          this.onScrollToEnd();
        }
      }, options);
      const sentinel = document.querySelector('.ebay-search-scroll-sentinel');
      if (!sentinel) {
        console.error('Sentinel element not found.');
      } else {
        this.observer.observe(sentinel);
      }
    }
    setupImpressionObserver(itemId) {
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 1.0
      };
      const observer = new IntersectionObserver(_ref2 => {
        let [entry] = _ref2;
        if (entry.isIntersecting) {
          this.trackEbayImpression(itemId);
          observer.disconnect();
        }
      }, options);
      const impression = document.getElementById(`impression-observer-${itemId}`);
      if (!impression) {
        console.error(`#impression-observer-${itemId} not found.`);
      } else {
        observer.observe(impression);
      }
    }
  }
  _exports.default = _default;
});