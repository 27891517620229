define("discourse/plugins/discourse-ebay-ads/discourse/admin/controllers/admin-plugins-ebay", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _controller, _object, _tracking, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsEbayController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "allSellers", [_tracking.tracked], function () {
      return [];
    }))();
    #allSellers = (() => (dt7948.i(this, "allSellers"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "ebaySeller", [_tracking.tracked], function () {
      return "";
    }))();
    #ebaySeller = (() => (dt7948.i(this, "ebaySeller"), void 0))();
    init() {
      super.init(...arguments);
      (0, _ajax.ajax)("/ebay/seller/info").then(result => {
        this.allSellers = result.sellers;
      }).catch(_ajaxError.popupAjaxError);
    }
    addSeller() {
      this._addSeller(this.ebaySeller, false);
    }
    static #_3 = (() => dt7948.n(this.prototype, "addSeller", [_object.action]))();
    blockSeller() {
      this._addSeller(this.ebaySeller, true);
    }
    static #_4 = (() => dt7948.n(this.prototype, "blockSeller", [_object.action]))();
    _addSeller(ebay_username, blocked) {
      if (ebay_username !== "") {
        const encodedSeller = encodeURIComponent(ebay_username);
        (0, _ajax.ajax)(`/ebay/seller/add/${encodedSeller}.json`).then(result => {
          if (result.status == "ok") {
            const index = this.allSellers.findIndex(seller => seller.ebay_username === ebay_username);
            if (index == -1) {
              const newSeller = {
                username: null,
                ebay_username: ebay_username,
                listings_count: 0,
                blocked: false
              };
              this.allSellers.unshift(newSeller);
              this.notifyPropertyChange('allSellers');
            }
            if (blocked) {
              this._blockSeller(ebay_username);
            }
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
    _blockSeller(ebay_username) {
      if (ebay_username != "") {
        const encodedUsername = encodeURIComponent(ebay_username);
        (0, _ajax.ajax)(`/ebay/seller/block/${encodedUsername}.json`).then(result => {
          if (result.status == "ok") {
            this.allSellers.forEach(seller => {
              if (seller.ebay_username === ebay_username) {
                const index = this.allSellers.findIndex(seller => seller.ebay_username === ebay_username);
                if (index !== -1) {
                  let modifiedSeller = Object.assign({}, this.allSellers[index]);
                  modifiedSeller.blocked = true;
                  this.allSellers.splice(index, 1);
                  this.allSellers.splice(index, 0, modifiedSeller);
                  this.notifyPropertyChange('allSellers');
                }
              }
            });
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
    _unblockSeller(ebay_username) {
      if (ebay_username !== "") {
        const encodedUsername = encodeURIComponent(ebay_username);
        (0, _ajax.ajax)(`/ebay/seller/unblock/${encodedUsername}.json`).then(result => {
          if (result.status == "ok") {
            const index = this.allSellers.findIndex(seller => seller.ebay_username === ebay_username);
            if (index !== -1) {
              let modifiedSeller = Object.assign({}, this.allSellers[index]);
              modifiedSeller.blocked = false;
              this.allSellers.splice(index, 1);
              this.allSellers.splice(index, 0, modifiedSeller);
              this.notifyPropertyChange('allSellers');
            }
          }
        }).catch(_ajaxError.popupAjaxError);
      }
    }
    unblockSellerFromTable(ebay_username) {
      this._unblockSeller(ebay_username);
    }
    static #_5 = (() => dt7948.n(this.prototype, "unblockSellerFromTable", [_object.action]))();
    blockSellerFromTable(ebay_username) {
      this._blockSeller(ebay_username);
    }
    static #_6 = (() => dt7948.n(this.prototype, "blockSellerFromTable", [_object.action]))();
    deleteSellerFromTable(ebay_username) {
      const encodedUsername = encodeURIComponent(ebay_username);
      encodedUsername = encodedUsername.replace(/\./g, '%2E');
      (0, _ajax.ajax)(`/ebay/seller/remove/${encodedUsername}`).then(result => {
        if (result.status == "ok") {
          this.allSellers = this.allSellers.filter(seller => seller.ebay_username !== ebay_username);
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_7 = (() => dt7948.n(this.prototype, "deleteSellerFromTable", [_object.action]))();
    dumpListingsFromTable(ebay_username) {
      let encodedSeller = encodeURIComponent(ebay_username);
      encodedSeller = encodedSeller.replace(/\./g, '%2E');
      (0, _ajax.ajax)(`/ebay/seller/dump/${encodedSeller}.json`).then(result => {
        if (result.status == "ok") {
          const index = this.allSellers.findIndex(seller => seller.ebay_username === ebay_username);
          if (index !== -1) {
            let modifiedSeller = Object.assign({}, this.allSellers[index]);
            modifiedSeller.listings_count = 0;
            this.allSellers.splice(index, 1);
            this.allSellers.splice(index, 0, modifiedSeller);
            this.notifyPropertyChange('allSellers');
          }
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_8 = (() => dt7948.n(this.prototype, "dumpListingsFromTable", [_object.action]))();
    fetchListings(username) {
      let encodedUser = encodeURIComponent(username);
      encodedUser = encodedUser.replace(/\./g, '%2E');
      (0, _ajax.ajax)(`/ebay/user/update/${encodedUser}.json`).then(result => {
        console.log(result);
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_9 = (() => dt7948.n(this.prototype, "fetchListings", [_object.action]))();
  }
  _exports.default = AdminPluginsEbayController;
});