define("discourse/plugins/discourse-ebay-ads/discourse/admin/routes/ebay-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: 'admin.adminPlugins',
    path: '/plugins',
    map() {
      this.route('ebay');
    }
  };
});