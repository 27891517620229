define("discourse/plugins/discourse-ebay-ads/discourse/preferences/initializers/ebay-preferences-settings", ["exports", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/ajax-error", "@ember/application"], function (_exports, _ajax, _pluginApi, _ajaxError, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "extend-for-ebay-preferences",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8", api => {
        api.modifyClass('controller:preferences/profile', {
          pluginId: 'discourse-ebay-ads',
          actions: {
            save() {
              this._super(...arguments);
              const ebayUserSettings = (0, _application.getOwner)(this).lookup('service:ebay-user-settings');
              const {
                discourseId,
                ebayUsername,
                hideListings
              } = ebayUserSettings;
              const uname = ebayUsername.toLowerCase();
              let encodedUsername = encodeURIComponent(uname);
              encodedUsername = encodedUsername.replace(/\./g, '%2E');
              let url = `/ebay/user/update_settings/${encodedUsername}?user_id=${discourseId}&hidden=${hideListings}`;
              if (ebayUsername === "") {
                url = `/ebay/user/clear_settings/${discourseId}`;
              }
              (0, _ajax.ajax)(url).catch(_ajaxError.popupAjaxError);
            }
          }
        });
      });
    }
  };
});