define("discourse/plugins/discourse-ebay-ads/discourse/preferences/services/ebay-user-settings", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EbayUserSettingsService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "discourseId", [_tracking.tracked], function () {
      return null;
    }))();
    #discourseId = (() => (dt7948.i(this, "discourseId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "ebayUsername", [_tracking.tracked], function () {
      return "";
    }))();
    #ebayUsername = (() => (dt7948.i(this, "ebayUsername"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "hideListings", [_tracking.tracked], function () {
      return false;
    }))();
    #hideListings = (() => (dt7948.i(this, "hideListings"), void 0))();
  }
  _exports.default = EbayUserSettingsService;
});