define("discourse/plugins/discourse-ebay-ads/discourse/templates/connectors/user-custom-preferences/ebay-preferences", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if siteSettings.enable_ebay_ads}}
    {{ebay-preferences-settings model=this.model}}
  {{/if}}
  */
  {
    "id": "KnO1yZKH",
    "block": "[[[41,[30,0,[\"siteSettings\",\"enable_ebay_ads\"]],[[[1,\"  \"],[1,[28,[35,1],null,[[\"model\"],[[30,0,[\"model\"]]]]]],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `siteSettings` property path was used in the `discourse/plugins/discourse-ebay-ads/discourse/templates/connectors/user-custom-preferences/ebay-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.siteSettings}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"if\",\"ebay-preferences-settings\"]]",
    "moduleName": "discourse/plugins/discourse-ebay-ads/discourse/templates/connectors/user-custom-preferences/ebay-preferences.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});