define("discourse/plugins/discourse-ebay-ads/discourse/preferences/components/ebay-preferences-settings", ["exports", "@glimmer/component", "discourse/lib/ajax", "discourse/lib/plugin-api", "discourse/lib/ajax-error", "@ember/service"], function (_exports, _component, _ajax, _pluginApi, _ajaxError, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EbayPreferencesSettings extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "ebayUserSettings", [_service.inject]))();
    #ebayUserSettings = (() => (dt7948.i(this, "ebayUserSettings"), void 0))();
    get ebayUsername() {
      return this.ebayUserSettings.ebayUsername;
    }
    set ebayUsername(value) {
      this.ebayUserSettings.ebayUsername = value;
    }
    get hideListings() {
      return this.ebayUserSettings.hideListings;
    }
    set hideListings(value) {
      this.ebayUserSettings.hideListings = value;
    }
    get discourseId() {
      return this.ebayUserSettings.discourseId;
    }
    set discourseId(value) {
      this.ebayUserSettings.discourseId = value;
    }
    constructor() {
      super(...arguments);
      (0, _pluginApi.withPluginApi)("0.8", api => {
        this.discourseId = this.args.model.id;
        (0, _ajax.ajax)(`/ebay/user/settings/${this.discourseId}`).then(result => {
          if (result.seller) {
            this.ebayUsername = result.seller.ebay_username;
            this.hideListings = result.seller.hidden;
          }
        }).catch(_ajaxError.popupAjaxError);
      });
    }
  }
  _exports.default = EbayPreferencesSettings;
});